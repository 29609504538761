/* src/pages/Register.css */
.register-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #000;
    color: #fff;
}

.register-form-container {
    background-color: #1c1c1c;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: auto;
}

h1 {
    margin-bottom: 1rem;
}

label {
    display: block;
    margin-bottom: 1rem;
    text-align: left;
}

input {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: #2c2c2c;
    color: #fff;
}

button {
    width: 100%;
    padding: 0.75rem;
    margin-top: 1rem;
    border: none;
    border-radius: 5px;
    background-color: #ff0000;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #cc0000;
}

.error {
    color: #ff4d4d;
    margin-bottom: 1rem;
}

/* src/components/Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    padding: 1rem 2rem;
  }
  
  .header-title {
    font-size: 1.5rem;
    color: #fff;
  }
  
  .header-buttons {
    display: flex;
    gap: 2rem;
  }
  
  .header-button {
    color: #fff;
    background-color: #1f1b1b;
    padding: 0.5rem 1rem;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .header-button:hover {
    background-color: #c60e0e;
  }
  
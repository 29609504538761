.create-problem-page {
    background-color: black;
    color: white;
    min-height: 100vh;
    padding: 20px;
}

.create-problem-form {
    max-width: 600px;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea,
.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #333;
    color: white;
}

.testcase-section {
    margin-top: 20px;
}

.testcase-section h2 {
    margin-bottom: 10px;
}

.submit-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.submit-button:hover {
    background-color: darkred;
}

.message {
    margin-top: 20px;
    color: green;
}

.created-testcases {
    margin-top: 20px;
}

.created-testcases h2 {
    margin-bottom: 10px;
}

.testcase-display {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    background-color: #444;
    margin-bottom: 10px;
}

.testcase-display div {
    margin-bottom: 5px;
}

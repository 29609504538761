.submissions-page {
    background-color: black;
    color: white;
    min-height: 100vh;
}

.submissions-content {
    padding: 20px;
}

.submissions-list {
    margin-top: 20px;
}

.submission-item {
    background-color: #333;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #444;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submission-item:hover {
    background-color: #444;
}

.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #333;
    padding: 20px;
    border: 1px solid #444;
    width: 80%;
    max-width: 600px;
    color: #fff;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close-button:hover,
.close-button:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.modal-content pre {
    background-color: #222;
    padding: 10px;
    border: 1px solid #444;
    color: #fff;
    overflow: auto;
}
